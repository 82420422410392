.modal-confirm {
    border-radius: 20px;

    margin-top: 5%;

    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-modal-button-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}