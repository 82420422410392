@import './AllProducts/AllProducts.scss';
@import './AddProduct/AddProduct.scss';
@import './ShowSingleProduct/ShowSingleProduct.scss';

.inner-form-products {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}
