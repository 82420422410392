.add-product-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .any-product-data {
        margin-right: 20px;
    }

    .ground-coffee-data {
        margin-left: 20px;
    }
}

.image-upload-container-addProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkbox-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    margin-bottom: 20px;
}

.ways-of-preparation-icon{
    max-width: 64px;
}