.image-upload-container-updateCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-upload-container-updateCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-entry-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
