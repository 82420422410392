.page-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.page-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.paper-form {
    h1 {
        font-size: 52px;
        color: $white;
        font-weight: 200;
        line-height: 1.8;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin-bottom: 0px;

        text-align: center;
        text-transform: capitalize;
    }
}

.image-preview {
    width: 246px;

    margin-bottom: 20px;

    border-radius: 15%;
}

.icon-preview {
    width: 184.5px;

    margin-bottom: 20px;

    border-radius: 15%;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-form-container {
    border-collapse: separate;
    border-radius: 10%;
}

.table-view-page-btn {
    align-self: flex-start;
}

.table-page-toolbar {
    display: 'flex';
    flex-direction: row;
    justify-content: space-between;

    background-color: rgb(107, 107, 107);

    width: 100%;
}

.header {
    width: 100%;
    //min-width: 600px;
}

.image-entry-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}