.login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.select-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 100%;

    max-width: 400px;

    margin-bottom: 50px;

    .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
        color: $white;
        font-size: 1rem;
        line-height: 1.437em;
        letter-spacing: 0.00938em;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;

        top: 20px;
    }

    svg {
        fill: white;
    }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-a50rpl-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    width: 400px;
}
