.single-order-page-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .order-content-container {
        margin-right: 20px;
    }

    .order-details-container {
        margin-left: 20px;
    }
}
