.respond-modal-button-container {
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
}

.respond-to-order-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.modal-respond-paper {
    max-width: 100%;
    min-width: none;
}

@media only screen and (max-width: 1400px) {
    .respond-to-order-data {
        width: 100%;
        display: flex;
        flex-direction: column;   
    }
  }