.categories-page {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;

	.categories-part {
		margin-right: 20px;
	}

	.subcategories-part {
		margin-left: 20px;
	}
}
