.tags-chipList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    list-style: none;
}

.tags-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    text-align: center;
    justify-items: center;
}
