@import './constants.scss';

@import '../templates/AppTemplate/AppTemplate.scss';

//---------IMPORT COMPONENTS

@import '../components/NavBar/NavBar.scss';
@import '../components/ProductCategorySelect/ProductCategorySelect.scss';
@import '../components/ProductSubCategorySelect/ProductSubCategorySelect.scss';
@import '../components/AddSize/AddSize.scss';
@import '../components/AddSection/AddSection.scss';
@import '../components/OnboardingAnswersTypeSelect/OnboardingAnswersTypeSelect.scss';
@import '../components/ProductsTable/ProductsTable.scss';
@import '../components/RejectionReasonSelect/RejectionReasonSelect.scss';
@import '../components/LanguageSelection/LanguageSelection.scss';
@import '../components/TagsCollection/TagsCollection.scss';
@import '../components/DeleteConfirmationModal/DeleteConfirmationModal.scss';

//---------IMPORT PAGES
@import '../pages/Authentication/Login/Login.scss';
@import '../pages/Employee/EmployeePage.scss';
@import '../pages/Order/OrderPage.scss';
@import '../pages/Product/ProductsPage.scss';
@import '../pages/ShopLocation/ShopLocationPage.scss';
@import '../pages/Article/ArticlePage.scss';
@import '../pages/Categories/CategoryPage.scss';
@import '../pages/Review/ReviewPage.scss';
@import '../pages/AddPointsToUser/AddPointsToUser.scss';
@import '../pages/LandingPageContent/LandingPageContent.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

body {
    font-family: 'Raleway', sans-serif !important;
    background-color: $background-color;
    //min-width: 500px;

    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.coordinate {
    display: none !important;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
