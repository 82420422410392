@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap');

$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$grey: rgb(70, 69, 69);
$light-grey: rgb(107, 107, 107);

$light-blue: rgb(106, 175, 253);

$navbar-color: $black;

$background-color: $grey;
