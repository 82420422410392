.add-article-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .article-data {
        margin-right: 20px;
    }

    .section-data {
        margin-left: 20px;
    }
}

.image-upload-container-addArticle {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.paper-form-section-container-addArticle {
    text-align: center;

    h1 {
        font-size: 52px;
        color: $white;
        font-weight: 200;
        line-height: 1.8;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin-bottom: 10px;

        text-transform: capitalize;
    }
}
