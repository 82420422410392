.sizes-chipList {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    
    .pchip {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20rem;
        padding: 2px;
    }
}

.category-select-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;

    .category-select-column {
        display: flex;
        flex-direction: column;
    }
}
