@import './components/RespondToOrderModal/RespondToOrderModal.scss';

.modal-container-respond {
    display: flex;
    flex-direction: column;
    justify-self: center;
    border: none;
    outline: none;
    overflow-y: scroll !important;
}

.modal-respond {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-y: scroll !important;
}

.ready-row {
    background-color: orange;
}