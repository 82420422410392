.single-section-data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 15px;

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .image-input-container {
        margin-top: 15px;
        display: flex;
        align-self: center;

        flex-direction: column-reverse;
    }
}

.section-image {
    align-self: center;
}
